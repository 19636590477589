import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Layout } from 'containers';
import { Banner, TourCard } from 'components';
import { BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';

const GermanTourPage = ({ data }) => (
  <Layout page="tour/nz-nature-safari-tour">

    <Helmet
      title="German-Speaking NZ Tours"
      meta={[
        {
          name: 'description', content: 'Guided tours of New Zealand with an experienced German-speaking tour guide.',
        },
        { name: 'og:title', content: 'German-Speaking NZ Tours' },
        {
          name: 'og:description', content: 'Guided tours of New Zealand with an experienced German-speaking tour guide.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/tour/nz-nature-safari-tour/` }]}
    />

    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/german-landing-page-hero.jpg?auto=format">
      <h1 className="c-heading c-heading--h1">German-Speaking NZ Tours</h1>
    </Banner>
    <section className="l-section">
      <div className="c-heading c-heading--h3 header-center">
        Explore New Zealand with a German-speaking guide
      </div>
      <div className="l-container l-container--small">
        <div>
          If you’re looking to take a trip of a lifetime and get a flavour of New Zealand’s scenic and cultural highlights, then look no further!
        </div>
        <br />
        <div>
          Alongside our Haka Tours which are run by English-speaking guides, we also have two New Zealand Tours hosted by experienced German-speaking guides, which are operated by our sister company ANZ Nature Tours.
        </div>
        <br />
        <div>
          Our 21-day Safari Tour and 20-day Nature Tour offer fixed departures through the New Zealand summer season (Oct - April).
          These small-group coach tours (with a maximum of 14 guests on <a href="/haka-plus-tours/new-zealand-safari-tour/">the Safari Tour</a> and
          9 on <a href="/haka-plus-tours/new-zealand-nature-tour/">the premium Nature Tour</a>) offer a more personalised experience and run from Auckland to Christchurch (or reverse),
          combining nature, adventure and sightseeing experiences across the North and South Island.
        </div>
        <br />
        <div>
          These scheduled group tours are aimed at those who desire a little more comfort when they travel - we will stay in comfortable lodges, motels, hotels and resorts.
        </div>
        <br />
        <div>
          With accommodation, transport, many meals and an expert german-speaking guide, this is the ultimate stress-free experience of New Zealand!
        </div>
        <br />
        <div>
          We also offer <a href="https://hakatours.com/private-tours/" target="_blank" rel="noopener noreferrer">private tours</a> and
          <a href="https://anznaturetours.com/travel-new-zealand/self-drive/" target="_blank" rel="noopener noreferrer"> self-drive itineraries</a> so if you are interested in finding out more about these style of tours,
          contact the team at <a href="mailto:reservations@anznaturetours.com">reservations@anznaturetours.com</a> or  +64 4 385 1024
        </div>
        <br /><br />
        <hr />
        <br /><br />
        <div>
          Entdecken Sie auf unseren unvergesslichen Kleingruppenreisen die schönsten landschaftlichen und kulturellen Höhepunkte Neuseelands, wo heiße Vulkane auf eisige Gletscher treffen.
        </div>
        <br />
        <div>
          Neben unseren Haka Reisen, die von englischsprachigen Reiseleitern durchgeführt werden, bieten wir auch zwei Reisen mit erfahrenen deutschsprachigen Reiseleitern an, die von unserer Schwesterfirma ANZ Nature Tours durchgeführt werden.
        </div>
        <br />
        <div>
          Auf diesen deutschsprachigen Rundreisen, entdecken wir gemeinsam traumhafte Landschaften und intensive Begegnungen mit den Menschen vor Ort runden das Erlebnis auch kulturell ab.
        </div>
        <div>
          Unser Reiseleiter hält dabei auch spannende Geheimtipps fernab ausgetretener touristischer Pfade für uns bereits.
        </div>
        <br />
        <div>
          Auf diesen Kleingruppenreisen mit maximal 14 Teilnehmern,lernen Sie Ihre Mitreisenden im kleinen Kreis noch schneller kennen. So begegnen wir den Menschen in ihrem Alltag, und erleben Neuseelands Traditionen und Kulturen aus erster Hand.
        </div>
        <br />
        <div>
          Mit komfortablen Unterkünften, Transport, vielen inklusiv Leistungen und unseren fachkundigen Reiseleitern ist dies die ultimative stressfreie Erfahrung Neuseelands.
        </div>
        <br />
        <div>
          Sie möchten individuell reisen, haben aber keine Zeit selbst zu planen? Unsere geschulten Reiseexperten kümmern sich um Ihre gesamte Reiseplanung.
          ANZ Nature Tours organisiert Individualreisen aus einer Hand mit Unterkünften, Mietwagen, Reiserouten und Aktivitäten. Wir erstellen Ihre Traumreise genau nach Ihren Vorlieben – und das vollkommen kostenlos und unverbindlich!
        </div>
        <br />
        <div>
          Kontaktieren Sie das Team unter <a href="mailto:reservations@anznaturetours.com">reservations@anznaturetours.com</a> oder +64 4 385 1024.
        </div>
        <br /><br />
        <div className="c-heading c-heading--h3 header-center">
          German-speaking Tours
        </div>
        <div className="l-german-tour-list__grid">
          {FORMAT_GRAPH_DATA(data.allTourPages).map(item => (
            <TourCard
              key={item.id}
              data={item}
            />
          ))}
        </div>
      </div>
    </section>
  </Layout>
);

GermanTourPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
                hidden_category: { eq: false },
                is_german_tour: { eq: true}
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          is_german_tour
          order
          is_popular
          destination_ids
          destination_groups
          category_ids
          starting_price
          number_of_days
          background_color
          short_description_markdown
          parent_name
          absolute_slug
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          category_icons {
            id
            name
            tooltip
            svg_text
          }
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }
  }
`;

export default GermanTourPage;
